<template>
	<div class="relative overflow-x-auto grow flex flex-col mt-32 mx-8 rounded-md">
	  <!-- Header -->
	  <div class="flex justify-between items-center mb-4">
		<div class="flex items-center space-x-2">
		  <ClipboardDocumentCheckIcon class="w-5 h-5 text-teal-500" />
		  <h1 class="text-2xl p-2">Add New Attribute</h1>
		</div>

		<button
		  @click="submitForm"
		  class="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-lg inline-block"
		>
		  Add Attribute
	  </button>
		</div>
  
	  <!-- Form Container -->
	  <div class="flex justify-between items-start space-x-4">
		<!-- General Information -->
		<div class="flex-1 p-4 bg-white rounded shadow-md">
		  <form @submit.prevent="submitForm">
			<!-- Product Name -->
			<div class="mb-4">
			  <h1 class="text-xl  mb-4">General Information</h1>
  
			  <label for="productName" class="block text-gray-600 font-medium mb-2">Attribute Name</label>
			  <div class="flex">
				<input
				v-model="form.attributeName"
				type="text"
				id="productName"
				class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
				placeholder="Enter product name"
				required
			  />
			  </div>
			
			</div>
  
			<!-- Description -->
			<div class="mb-4">
			  <label for="description" class="block text-gray-700 font-medium mb-2">Description</label>
			
			  <div class="flex">
				<textarea
				v-model="form.description"
				id="description"
				class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
				placeholder="Enter product description"
				rows="5"
				required
			  ></textarea>
			  </div>
			  
			</div>
			
			<div class="mb-4 flex">
				<input 
	     		type="text" 
		        v-model="inputValue" 
				@keyup.space.prevent="addItem" 
				@keyup.enter.prevent="addItem"
				placeholder="Type something and press space to add item"
		class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
	  />
	  </div>
	  <ul class="list-none p-0">
		<li 
		  v-for="(item, index) in items" 
		  :key="index" 
		  class="bg-gray-100 rounded p-2 mb-2"
		>
		  {{ item }}
		</li>
	  </ul>
		
		  </form>
		</div>
  
	  </div>
	</div>
  </template>
  
  <script>
  import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/solid';
  import axios from 'axios';
  import { useRouter } from 'vue-router';

  export default {
	name: 'AddAttributeView',
	components: { ClipboardDocumentCheckIcon },
	setup() {
		const router = useRouter();
		return { router };
	},
	data() {
	  return {
		inputValue: '',
		items: [],
		form: {
			attributeName: '',
		    description: '',

		},
	  };
	},
	methods: {
	  submitForm() {
		// Handle form submission
        axios.post('t/products/attributes/create', 
		{
        name: this.form.attributeName,
        description: this.form.description,
        options: this.items
    },
    {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
)
      .then(response => {
        this.attributes = response.data["data"];
        // Reset form
        this.form = {
		  attributeName: '',
		  description: '',
		  
		};
		this.inputValue = '';
		this.items = [];
		this.$router.go(-1);


      })
      .catch(error => {
        console.log(error);
      });
		
	  },

	addItem() {
		if (this.inputValue.trim() !== '') {
		  this.items.push(this.inputValue);
		  this.inputValue = '';
		}
	  },
	},
  };
  </script>
  
  <style scoped>
  /* Add any additional styles here */
  </style>
  



  