<template>
  
  <div class="h-dvh grid xs:grid-cols-1 md:grid-cols-2 gap-4">
      <div v-if="isLogin" class="m-4 pt-64 w-1/2" >
        <h1 class="font-medium text-2xl mb-2 text-secondary-color">Get Started Now</h1>
        <h1 class="font-regular text-sm text-secondary-color" >Enter your credentials to access your account</h1>
        
        <div class="w-350 mt-8">
          <v-text-field label="Mobile number" id="phone"           
        :rules="[rules.required, rules.counter]"
        v-model="mobileNumber"
        variant="underlined"></v-text-field>
        </div>
        <div class="content-center  flex mt-4 justify-center">
          <button
        class="font-regular text-xl p-2 rounded-md text-primary-color center w-250 bg-primary-color-Ac"
        @click="loginUser()"
        density="default"> 
        Sign in
      </button>
    </div>
    
        </div>
        <div v-else class='m-4 pt-64 w-1/2'>

          <VerifyView 
          :value="isLogin"
          :phone=mobileNumber 
          :isLogin=true
          />
        </div>
        <div class="m-4 w-1/2 p-4 rounded-md content-center bg-primary-color-Ac" >
          <h1 class="font-medium text-2xl mb-2 text-primary-color" >The  simplesst  way to manage<br/> your workforce </h1>
        <h1 class="font-regular text-sm text-primary-color" >Enter your credentials to access your account</h1>
      </div>
      
    </div>

  </template>

  <script>
    import axios from 'axios'
    import VerifyView from '../../components/verify.vue'; 

  
    export default {
      components: {
        VerifyView,
      },
     data: () => ({
      step: 1,
      mobileNumber: '',
      dialCode: '20',
      loader: false,
      isLogin: true,
      errorMessages:new Map(),
      rules: {
        required: value => !!value || 'This Field is Required.',
        counter: value => value.length <= 15 || 'Max 15 characters',
      },
    }),
    props: {
      source: String
    },
    methods: {

      loginUser() {  
    this.loader = true;
    var data={
      "dail_code":this.dialCode,
      "phone_number":parseInt(this.mobileNumber),
    };

    axios.post('/t/accounts/login',data,{
    
    }).then(response=>{
    if(response.status==200){
      this.isLogin=false;
      this.loader = false;
    }else{

    }

  }).catch(error=>{
    this.loader = false;
    if(error.response.status==422){
      this.errorMessages=error.response.data['errors'];
    }
  });
  this.loader = false;
  },

  
      
    },
  }
  </script>
  <style scoped>
  .v-application .rounded-bl-xl {
      border-bottom-left-radius: 300px !important;
  }
  .v-application .rounded-br-xl {
      border-bottom-right-radius: 300px !important;
  }
  </style>