<template>
  <div class="relative overflow-x-auto grow flex-col mt-32 mx-8 flex rounded-md">
    <router-link :to="{ name: 'create-attribute' }">
      <button class="w-160 bg-teal-500 hover:bg-teal-700 mb-2 text-white font-bold py-2 px-4 rounded-md">
        Add Attributes
      </button>
    </router-link>

    <table class="grow text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-teal-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3"><input type="checkbox" id="jack" value="Jack" v-model="checkedNames" /></th>
          <th scope="col" class="px-6 py-3">Name</th>
          <th scope="col" class="px-6 py-3">Description</th>
          <th scope="col" class="px-6 py-3">Options</th>
          <th scope="col" class="px-6 py-3">Created at</th>
          <th scope="col" class="px-6 py-3">Updated at</th>
          <th scope="col" class="px-6 py-3">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="attribute in attributes" :key="attribute.id">
          <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <input type="checkbox" :id="attribute.id" :value="attribute" v-model="checkedNames" />
          </td>
          <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ attribute.name }}</td>
          <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ attribute.description }}</td>
          <td class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {{ displayOptions(attribute.options)}}
          </td>

          <td class="px-6 py-4">{{ formatDate(attribute.created_at) }}</td>
          <td class="px-6 py-4">{{ formatDate(attribute.updated_at) }}</td>
          <td class="px-6 py-4"></td>
          <!-- <td class="px-6 py-4">
            <router-link :to="{ name: '', params: { id: attribute.id } }">
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md">Edit</button>
            </router-link>
            <button @click="deleteAttribute(attribute.id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md">Delete</button>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'AttributesView',
  data() {
    return {
      attributes: [],
      checkedNames: [],
    };
  },
  mounted() {
    this.getAttributes();
  },
  methods: {
    displayOptions(options) {
      const names = options.map(option => option.name);
      if (names.length > 4) {
        return names.slice(0, 3).join(', ') + '...';
      } else {
        return names.join(', ');
      }
    },
    getAttributes() {
      axios.get('/t/products/attributes', {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
      })
      .then(response => {
        this.attributes = response.data["data"];

      })
      .catch(error => {
        console.log(error);
      });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
      }
    },
    deleteAttribute(id) {
      axios.delete(`/t/products/attributes/${id}`, {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
      })
      .then(() => {
        this.getAttributes();  // Refresh the list after deletion
      })
      .catch(error => {
        console.log(error);
      });
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
button {
  margin: 0 5px;
}
</style>


