<template>
    <div class="relative overflow-x-auto grow flex-col mt-40 mx-8 flex rounded-md">


      <div class="mb-4  flex border-b-2 w-400   border-teal-500 cursor-pointer border- pb-2">
        <h1
      v-for="item in items"
      :key="item"
      :class="['h1', { selected: selectedItem === item }]"
      @click="selectItem(item)"
    >
      {{ item.charAt(0).toUpperCase() + item.slice(1) }}
    </h1>
      </div>
      <div>
        <router-view/>          
      </div>

    </div>
  </template>
  
  <script>
import routes from '../../router/index.js';

  export default {
    data() {
      return {
        productId: null,
        items: ['overview', 'details', 'images' ,'variants' ],
        selectedItem: 'overview' // Default selected item
      };
    },
    setup() {


    },
    created() {
      this.fetchProduct();
      this.lastSegment();
    },
    components:{
     
    },
    methods: {
      lastSegment() {
        const url = this.$route.path;
      const lastSlashIndex = url.lastIndexOf('/');
      const lastSegment = url.substring(lastSlashIndex + 1);
      this.selectedItem = lastSegment;
      },
      selectItem(item) {
      this.selectedItem = item;
      routes.push({ path: `/t/products/${this.productId}/${item}` }) 


    },
      fetchProduct() {
        this.productId = this.$route.params.id;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  .h1{
    padding: 8px 16px;
  }
  .selected {
  background-color: white;
  color:#009688;
  border-radius: 20px;
}
  </style>
  