<template>
  <div :class="sidebarClass">
    <div>
    
    <div class="space-x-4 mb-4 ">
    <button class="" @click="toggleMenu">
    <Icon icon="ic:round-menu" width="1.6rem" height="1.6rem"  style="color: #000000" />
    </button>
    </div>
   </div>

    <ul>
    <div @click="onTapNavigation('/statistics')" :class="`${!is_expanded ? 'pl-3 hover:bg-teal-100 mt-32 mb-4  rounded-md cursor-pointer':'mb-4 hover:bg-teal-100 p-2 rounded-md mt-32 cursor-pointer'} ${$route.path.includes('/statistics') ? 'selected' : ''}`">
    
    <!-- :class="`${is_expanded ? 'w-12 h-8 float-left  hover:bg-violet-100 p-1 rounded-md mr-4 mb-4':'w-6  h-6 float-left mr-2'}`" -->
    <Icon icon="tabler:home"  style="color: #000000" width="2rem" height="2rem" :class="computedClass"/>
  
    <h2 to="/statistics" :class="`${!is_expanded ? 'hidden':'ease-in delay-150 duration-350'}`">Statistic</h2>
    </div>
  
    <div @click="onTapNavigation('/products')"  :class="`${!is_expanded ? 'pl-3  hover:bg-teal-100 mb-4 rounded-md cursor-pointer':'mb-4 hover:bg-teal-100 p-2 rounded-md cursor-pointer'} ${$route.path.includes('/products') ? 'selected' : ''}`">
  
  <Icon icon="fluent-mdl2:product" width="2rem" height="2rem"  style="color: #000000" :class="computedClass"/>
  <h2  :class="`${!is_expanded ? 'hidden':''}`">Products</h2>

  </div>
  
  <div @click="onTapNavigation('/orders')" :class="`${!is_expanded ?  'pl-3  hover:bg-teal-100  mb-4 rounded-md cursor-pointer':'mb-4 hover:bg-teal-100 p-2 rounded-md cursor-pointer'} ${$route.path.includes('/orders') ? 'selected' : ''}`">
      <Icon icon="material-symbols:orders-outline-rounded" width="2rem" height="2rem"  style="color: #000000" :class="computedClass"/>
      <h2  :class="`${!is_expanded ? 'hidden':''}`">Orders</h2>
    
  </div>
  <div @click="onTapNavigation('/transactions')" :class="`${!is_expanded ? 'pl-3  hover:bg-teal-100 mb-4 rounded-md cursor-pointer':'mb-4 hover:bg-teal-100 p-2 rounded-md cursor-pointer'} ${ $route.path.includes('/transactions') ? 'selected' : ''}`">
      <Icon icon="grommet-icons:transaction" width="2rem" height="2rem"  style="color: #000000" :class="computedClass" />
    <h2  :class="`${!is_expanded ? 'hidden':''}`">Transaction</h2>
  
     </div>    
        <!-- Settings menu item with subitems -->
        <div :class="`${!is_expanded ?   'pl-3   hover:bg-teal-100 rounded-md mb-4 cursor-pointer' : 'hover:bg-teal-100  rounded-md '} ${ $route.path.includes('/settings') ? 'selecteds' : ''}`">
          <div @click="toggleSettings" :class="`${!is_expanded ? ' cursor-pointer':' hover:bg-teal-100 p-2 rounded-md cursor-pointer'} `">
      <Icon icon="tabler:settings" width="2rem" height="2rem"  style="color: #000000" :class="computedClass" />
        <h2  :class="`${!is_expanded ? 'hidden':''}`">Settings</h2>
  
     </div>   
     <ul v-if="settingsExpanded" class="pl-12 pb-2 pt-2 space-y-2 rounded-md cursor-pointer" :class="`${ $route.path.includes('/attributes') ?!is_expanded ?'selectedExpanded': 'selected' : ''}`">
            <li  @click="onTapNavigation('/settings/attributes')" >
              <h1  :class="`${!is_expanded ? 'hidden':''}`">Attributes</h1>
            </li>
          </ul>
  
    </div>
    
  
      </ul>
      </div>
    
    </template>
    <script >
    import { Icon } from '@iconify/vue';
  import router from '@/router';

    export default {


      components: {
        Icon,
      },
      data() {
        return {
          is_expanded:true,
          settingsExpanded:false
        };
      },
      methods:{
        toggleMenu(){
          this.is_expanded= !this.is_expanded;
        },
        toggleSettings  ()  {
      this.settingsExpanded = !this.settingsExpanded;
    },
    onTapNavigation(screen_navigation){

       router.push({ path: screen_navigation }) 
    
      }
    
      },
  
      computed:{
        sidebarClass() {
          const baseClasses = 'sidebar p-2 top-0 left-0 z-12  bg-background-color-2  peer-focus:left-0 ease-out delay-200 duration-300';
          const expandedClasses = this.is_expanded ?'w-[190px]' : 'w-[75px]';
          return `${expandedClasses} ${baseClasses}`;
        },
        computedClass() {
          return this.is_expanded
            ? 'w-12 h-6 mr-2 mb-4 float-left  center  hover:bg-teal-100  rounded-md'
            : 'w-12 h-12  p-1  rounded-md  center';
        },
      
      }
    };
    </script>
    
    <style lang="scss">
    
    .selected {
      @apply bg-teal-200; // Customize the color and other styles as needed
    }
    .selecteds {
      @apply bg-teal-100; // Customize the color and other styles as needed
    }
    </style>

