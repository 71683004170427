<template>
  <div class="bg-background-color h-screen w-screen flex" >
  <!-- <div class="w-1/2 h-screen  p-16 content-center  background-color">
    <h1 class="text-2xl"> Sign up to <br /><span class="text-primary-color-Ac">Manage</span> Awesome <br /> Product</h1>
  </div> -->
<div v-if="isRegister" class='w-600 lg:w-600 xl:w-600 2xl:w-600 m-auto p-4  content-center  bg-background-color' >
 <div class="h-auto px-8 py-8 content-center bg-primary-color rounded-large">
   <!-- <h1 class="content-center text-center">Register View</h1>  -->
  <form >
    <v-text-field label="username" variant="underlined" v-model="username"
    :rules="[rules.required,getErrorForField('username')]"
    :error-message="getErrorForField('username')"
:error="true"
    ></v-text-field>
<v-text-field label="First name" variant="underlined" 

:rules="[rules.required,]"


v-model="first_name"

></v-text-field>
<v-text-field label="Last name" id="lname" v-model="last_name"
:rules="[rules.required,]"
variant="underlined"

></v-text-field>
<v-text-field label="National Id" id="national_id" v-model="national_id"
:rules="[rules.required,]"
type="number"
variant="underlined"

></v-text-field>
<div>
<v-text-field label="Phone number" id="phone"           
 :rules="[rules.required, rules.counter]"

v-model="phone_number"
variant="underlined"></v-text-field>

</div>
<v-text-field label="Email" id="email" v-model="email" variant="underlined"
:rules="[rules.required,]"

></v-text-field>

<!-- <v-text-field label="Password" id="password" variant="outlined"></v-text-field>
<v-text-field label="Confirm password" id="phone" variant="outlined"></v-text-field> -->
<div class="content-center flex mt-4 justify-center"><v-btn
        class="text-none text-subtitle-1 center w-250"
        color="primary"
        size="large"
        variant="flat"
        :loading="loader"
        @click="registerUser()"
        density="default"> Sign up
      </v-btn>
    </div>
</form>  
</div>
</div>
<div v-else class='w-600 lg:w-600 xl:w-600 2xl:w-600 m-auto p-4  content-center  rounded-md bg-background-color'>
<VerifyView :value="isRegister" :phone=phone_number />
</div>




  </div>
</template>

<script>
import ApiApp from '/src/api.js'
import VerifyView from '../../components/verify.vue'; 
import axios from 'axios'

export default {
  name: 'Register View',
  methods: {
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
  registerUser(){
    this.loader = true;
    var data={
      "username":this.username,
      "first_name":this.first_name,
      "last_name":this.last_name,
      "email":this.email,
      "national_id":this.national_id,
      "dail_code":this.dail_code,
      "phone_number":this.phone_number,
      "password":'123456789',

    };

    // Fetch CSRF token from cookies
    const csrftoken = this.getCookie('csrftoken');
    axios.post('/t/accounts/sign-up',data,
    {
      headers: {
        'X-CSRFToken': csrftoken
      }
    }    
      ).then(response=>{
    if(response.status==200){
      this.isRegister=false
      this.loader = false;
    }else{

    }

  }).catch(error=>{
    this.loader = false;
    if(error.response.status==422){
      this.errorMessages=error.response.data['errors'];
    }
  });
  this.loader = false;
  },
  getErrorForField(field){
    if(!this.errorMessages.hasOwnProperty(field))
    return ''

    return this.errorMessages[field][0]
  },
  mounted() {
 
},
beforeUnmount() {
 
},
  },
  components: {
    VerifyView,
  } ,
   data() {
  return {
    loader: false,
    isRegister: true,
    errorMessages:new Map(),
    first_name:'',
    last_name:'',
    phone_number:'',
    national_id:"",
    username:'',
    email:'',
    dail_code:'20',
    rules: {
        required: value => !!value || 'This Field is Required.',
        counter: value => value.length <= 15 || 'Max 15 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
  

  };
}
}
</script>








