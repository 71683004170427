<template>
<div class="fixed"  v-if="imageDisplay" >
  <div  class=" bg-white p-4 rounded-md">
  <h1 class="pb-4" >Product Images</h1>

  <div class="grid grid-cols-2  gap-4">
  <div   
  class="col-span-1 bg-white group cursor-zoom-in  rounded-md overflow-hidden"
        @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
    :style="{ top: `${zoomY}px`, left: `${zoomX}px` }"

  >
  <div class="h-[450px]"> 

    <img :src="imageDisplay.image" 
    width="600" 
     alt="image Large" 
     :style="zoomStyle"
     class="w-full h-full  object-cover min-h-64 rounded-md" 
     />
  </div>
    

  </div>
  <div class="rounded-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  none">
    <div
  v-for="(image, index) in imagesProduct.slice(1)"
  :key="index"
  class="w-full h-32 pr-4"
>
<div class="w-32 h-32 object-cover cursor-pointer overflow-hidden">
  <img
    @click="changeImageDisplay(image)"
    :src="image.image"
    alt="Image"
    class="w-full h-full cursor-pointer object-cover rounded-md"
  />

</div>


</div>
  <div
    @click="triggerFileInput"
    class="border cursor-pointer rounded-md w-24 max-w-32 max-h-32 flex flex-col justify-center items-center"
  >
    <input
      type="file"
      ref="add_image_product"
      @change="onFileChange"
      style="display: none;"
    />
    <Icon icon="mage:image-upload" width="2rem" height="2rem" style="color: #000000" />
    <h4 class="text-center text-sm">Upload image</h4>
  </div>
 
</div>
  <!-- Add actions for image display -->
<div></div>
<div class="bottom-4 right-4 flex  justify-center items-center">
  
    <h1 @click="useCoverImage" class="bg-white border-2 m-2 cursor-pointer font-medium border-teal-100 py-2 px-4 rounded-lg">
    use a cover
    </h1>
    <button
      @click="deleteImage"
      class="bg-teal-500 text-white font-medium m-2 py-2 px-4 rounded-lg"
    >
      Delete image
    </button>
  </div>

</div>
</div>

</div>


    
  </template>
  
  <script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

  import { Icon } from '@iconify/vue';
  import axios from 'axios';


  export default {
    name: 'ImagesProduct',
    data(){
      return {
        selectedFile: null,
        imagesProduct:[],
        addedImages:[],
        imageDisplay:null,
        zoomX: 0,
        zoomY: 0,
        zoomStyle: {
        transform: 'scale(1)',
        transition: 'transform 0.3s ease-out'

      }
      }
    },
    setup() {
      
    },
    props: {
    },
    created() {
      this.fetchImagesProduct();
    },
    mounted() {
      
    },
    components:{
      Icon,
    
    },
    methods:{
      handleMouseEnter(event) {
      this.isHovered = true;
      this.updateZoomStyle(event);
    },
    handleMouseMove(event) {
      this.updateZoomStyle(event);
    },
    updateZoomStyle(event) {
      const { offsetX, offsetY, target } = event;
      const { width, height } = target;
      const xPercent = offsetX / width;
      const yPercent = offsetY / height;
      const zoomLevel = 1.5; // Adjust zoom level as needed
      this.zoomStyle = {
        transform: `scale(${zoomLevel})`,
        transformOrigin: `${xPercent * 100}% ${yPercent * 100}%`,
        transition: 'transform 0.3s ease-out'
      };
      this.zoomX = offsetX;
      this.zoomY = offsetY;
    },

    handleMouseLeave() {
      this.zoomStyle= {
        transform: 'scale(1)',
        transition: 'transform 0.5s ease-out'
      };
        },
      changeImageDisplay(image){
        this.imageDisplay =image;
      },
      useCoverImage(){
        const productId = this.$route.params.id;
        axios.post(`/t/products/${productId}/images/${this.imageDisplay.id}/cover`,{},
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
            })
          .then(response => {
            toast.success(response.data['message'],{
                autoClose: 1000,
            });  

          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
        
      },
      deleteImage(){
        const productId = this.$route.params.id;
        axios.delete(`/t/products/${productId}/images/${this.imageDisplay.id}/delete`,
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
            })
          .then(response => {
          
            this.imagesProduct=response.data['images'];
            this.imageDisplay=null;
            for (let image in this.imagesProduct){
              if(image.cover===true){
                this.imageDisplay=image;
                break;
              }
            }
            // If no cover image is found, default to the first image
            if(!this.imageDisplay){
              this.imageDisplay=this.imagesProduct[0]
            } 
            toast.success(response.data['message'],{
                autoClose: 1000,
            }); 
          
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
        
      },
      fetchImagesProduct(){
        const productId = this.$route.params.id;
        axios.get(`/t/products/${productId}/images`,
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
          }
        )
          .then(response => {
            this.imagesProduct=response.data['images'];
            
      for (let image in this.imagesProduct){
        if(image.cover===true){
          this.imageDisplay=image;
          break;
        }
      }
      // If no cover image is found, default to the first image
      if(!this.imageDisplay){
        this.imageDisplay=this.imagesProduct[0]
      } 

          
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
      },
      uploadImage(){
        const productId = this.$route.params.id;

        const formData = new FormData();

        for (let i = 0; i < this.selectedFile.length; i++) {
          formData.append('images', this.selectedFile[i]);
        }

        axios.post(`/t/products/${productId}/images/upload`,formData,
          {
            headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
          }
        )
          .then(response => {
            this.imagesProduct=response.data['images'];
            
      for (let image in this.imagesProduct){
        if(image.cover===true){
          this.imageDisplay=image;
          break;
        }
      }
      // If no cover image is found, default to the first image
      if(!this.imageDisplay){
        this.imageDisplay=this.imagesProduct[0].image
      } 

          
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
      },
      triggerFileInput() {
      this.$refs.add_image_product.click();
    },
    onFileChange(event) {
      this.selectedFile = event.target.files;

if(this.selectedFile.length >0){
  this.uploadImage();
}


      // this.uploadImage();
    },
    }
  };
  </script>
  
  <style scoped>
  /* Add styles for product details here */
  .cursor-zoom-in {
  cursor: zoom-in;
}
  </style>
  