import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
// import router from './router'
import router from './router';

import './index.css'
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/700-italic.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

import axios from 'axios'

// Vuetify
import 'vuetify/styles'
import { createVuetify  } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
  })

  axios.defaults.baseURL='http://206.189.54.83/api/';
  axios.defaults.headers.common['accept'] = 'application/json';
  axios.defaults.headers.common['content-Type'] = 'application/json';

createApp(App).use(router).use(vuetify).mount('#app')
