<template>
      <!-- Form Container -->
      <div class="flex justify-between items-start space-x-4">
      <!-- General Information -->
      <div v-if="product" class="flex-1 p-4 bg-white rounded shadow-md">
        <form @submit.prevent="submitForm">
          <!-- Product Name -->
          <div class="mb-4">
            <h1 class="text-xl  mb-4">General Information</h1>

            <label for="productName" class="block text-gray-600 font-medium mb-2">Product Name</label>
            <div class="flex">
              <input
              v-model="form.name"
              type="text"
              id="productName"
              class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter product name"
              required
            />
            </div>
          
          </div>

          <!-- Description -->
          <div class="mb-4">
            <label for="description" class="block text-gray-700 font-medium mb-2">Description</label>
          
            <div class="flex">
              <textarea
              v-model="form.description"
              id="description"
              class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter product description"
              rows="5"
              required
            ></textarea>
            </div>
            
          </div>


          <!-- <div class="flex items-center me-4">
            <input type="checkbox" id="checkbox" class="rounded bg-slate-800" v-model="form.has_variation" @change="onSelectVaraitaion" />
            <label class="ml-2 font-regular" for="checkbox">This product has different variants (colors, sizes, etc.)</label>
    </div>

    <div v-if="form.has_variation" >
      <div v-for="(attribute, index) in allAttributesDisplay" :key="index" class="transition duration-450  relative overflow-y-auto mt-4">
  <h1 class="text-xl mb-4">{{ attribute.name }}</h1>
  <div class="flex flex-wrap gap-2">
    <div v-for="(option, indexOption) in attribute.options" 
    :key="indexOption" class="flex-none ">
    <div :class="getClass(attribute,option)"
          @click="addOptionToProduct(attribute, option)">
      <h1>
          {{ option.name }}
        </h1>
    </div>  

    </div>
  </div>
</div>
</div> -->

      
        </form>
        <div class="bottom-4 right-4 flex  justify-end items-center">
      <button type="button" @click="cancelEditVariants"
       class="pt-2 py-2 px-8 rounded-lg border-teal-500">
    Cancel
</button>
  <button
    @click="updateDetails"
    class="bg-teal-500 text-white font-medium m-2 py-2 px-8 rounded-lg"
  >
    Save
  </button>
</div>
      </div>
      
</div>
</template>

<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {

    mounted(){
    this.getAllAttributes();
    this.fetchDetilsProduct();

  },
    data() {
        return {
            allAttributesDisplay:[],
            attributes:null,
            product: null,
            form: {
                name: null,
                description: null,
                category: null,
                variations: [],
                has_variation: false,
                has_discount: false
            },
        }
    },
    methods: {
        updateDetails() {
            const productId = this.$route.params.id;
            axios.patch(`/t/products/${productId}/update-details`,
            this.form,
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
          }
        )
          .then(response => {
            toast.success(response.data['message'],{
                autoClose: 1000,
            });  

            console.log(this.attributes);
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
        },
        fetchDetilsProduct() {
        const productId = this.$route.params.id;
        axios.get(`/t/products/${productId}/details`,
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
          }
        )
          .then(response => {
            this.product = response.data;
            this.form = response.data;
            this.product.variations.forEach((element) => {
            let optionsList = [];
            element.options.forEach((item) => {
                optionsList.push(item.option);
            });
            this.form.variations.push({
                value: element.value,
                id: element.variation,
                options: optionsList
            });
            })

            console.log(this.attributes);
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
      },


        addOptionToProduct(attribute,option){
  
        let attributeFound = false;

        if (this.form.variations.length === 0) {
            this.form.variations.push({
            id: attribute.id,
            value: attribute.name,
            options: [option.id]
            });
        } else {
            this.form.variations.forEach((element) => {
            if (element.id === attribute.id) {
            if (!element.options.includes(option.id)) {
                element.options.push(option.id);
            }else{
                element.options.splice(element.options.indexOf(option.id), 1); 
            }
            attributeFound = true;
        }
        });

            if (!attributeFound) {
            this.form.variations.push({
            id: attribute.id,
            value: attribute.name,
            options: [option.id]
            });
            }
        }
        },
        getClass(attribute, option) {
      const isSelectedOption = this.form.variations.some(
        (attr) => attr.id === attribute.id && attr.options.includes(option.id)
      );
      return {
          'border-2 text-center px-2 py-1 text-sm font-medium min-w-16 cursor-pointer rounded border-teal-500': isSelectedOption, // Example selected style
          'border text-center py-1 px-2 text-sm font-medium min-w-16 cursor-pointer rounded border-gray-300': !isSelectedOption // Example unselected style
        };
    },
        getAllAttributes() {
		// Handle form submission
        axios.get('t/products/attributes',{
        headers: {
            'Authorization': 'Token ' + '89ced5700ac5d65ea3fc19f0aee2ccbf2a8235bd'
        }
    }
)
      .then(response => {
        this.allAttributesDisplay = response.data["data"];     
      })
      .catch(error => {
        console.log(error);
      });
		
	  },
    }

}
</script>

<style>

</style>