<template>
  
  <div v-if="skus">
    <div> 
        <div class="bg-white p-4 px-6 rounded-md justify-center  grow">
    <!-- Content for the second div -->
    <table class=" text-x text-left overflow-scroll rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-600 uppercase border-b-2 border-gray-300  dark:bg-gray-700 dark:text-gray-400 border-1">
        <tr >

          <th scope="col" class="px-0 pb-2">Sku</th>
          <th v-for="(header, index) in variations" :key="index" scope="col" class="px-6 pb-2">{{ header.value }}</th>
          <th scope="col" class="px-6 pb-2">Price</th>
          <th scope="col" class="px-6 pb-2">Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr   class=" border-b " v-for="sku in skus" :key="sku.id">
          <td  class="px-0 py-4  text-gray-dd">{{ sku.sku }}</td>
          <td v-for="(option, index) in sku.options" :key="index" class="text-sm px-6 py-4 text-gray-dd">{{ option.value }}</td>
          
          <td class="px-6 py-4">
            <div class="flex">
              <input
              v-model="sku.price"
              type="number"
              min="0"
              id="price"
              class="flex-1 px-3 py-2 max-w-32 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
            />
            </div>
        </td>
          <td class="px-6 py-4"><div class="flex">
              <input
              v-model="sku.stock"
              type="number"
              min="0"
              id="stock"
              class="flex-1 px-3 py-2 max-w-32 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
            />
            </div>
        </td>
        </tr>
      </tbody>
    </table>
    <div class="bottom-4 right-4 flex  justify-end items-center">
      <button type="button" @click="cancelEditVariants"
       class="pt-2 py-2 px-8 rounded-lg border-teal-500">
    Cancel
</button>
  <button
    @click="updateVariants"
    :disabled="isDisabled"
    class="bg-teal-500 text-white font-medium m-2 py-2 px-8 rounded-lg"
  >
    Save
  </button>
</div>
  </div>
    
    
    </div>

    </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default {
    name: 'VariantsProduct',
    data(){
        return {
            skus:null,
            originalSkus:null,
            variations:null,
            isDisabled:false,
            isCancelDisabled:false
        }
    },

    created(){
        this.fetch_skus();
    },
    methods:{
      cancelEditVariants(){
        this.skus = JSON.parse(JSON.stringify(this.originalSkus));
      },
      updateVariants(){
        const updatedSkus = this.skus;
        const productId = this.$route.params.id;
        axios.patch(`/t/products/${productId}/update-variants`,
        {
          skus:updatedSkus
        },
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
          }
        )
          .then(response => {
            this.skus=response.data['skus'];
            this.variations=response.data['variations'];
            this.originalSkus = JSON.parse(JSON.stringify(this.skus));

            toast.success(response.data['message'],{
                autoClose: 1000,
            });  
          
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });

      },
        fetch_skus(){
        const productId = this.$route.params.id;
        axios.get(`/t/products/${productId}/variants`,
          {
            headers: {
              'Authorization': 'Token ' + localStorage.getItem('token')
            }
          }
        )
          .then(response => {
            this.skus=response.data['skus'];
            this.variations=response.data['variations'];
            this.originalSkus = JSON.parse(JSON.stringify(this.skus));


          
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
        }

    }
}
</script>

<style>
.cancel-button:disabled {
  background-color: #f0f0f0;
  border-color: #d0d0d0;
  cursor: not-allowed;
  color: #a0a0a0;
}
</style>