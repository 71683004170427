<template>
    <div class="settings">
  
        StatisticViews
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  
  export default {
    name: 'StatisticView',
    components: {
    }
  }
  </script>
  