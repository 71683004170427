<template>
  <div class="fixed overflow-x-auto max-h-full  min-h-full justify-between mt-32 mx-8 rounded-md">
    
 <div class="fixed overflow-x-auto">
  <table class="fixed text-sm   text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-teal-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3"><input type="checkbox" id="jack" value="Jack" v-model="checkedNames" /></th>
          <th scope="col" class="px-6 py-3">Subtotal</th>
          <th scope="col" class="px-6 py-3">Total</th>
          <th scope="col" class="px-6 py-3">Quantity</th>
          <th scope="col" class="px-6 py-3">Created at</th>
          <th scope="col" class="px-6 py-3">Updated at</th>
        </tr>
      </thead>
      <tbody>

        <tr v-on:click="onSelectedOrders(order.id)"  class=" border-b rounded-md hover:bg-teal-50 odd:bg-white even:bg-slate-50" v-for="order in orders" :key="order.id">
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><input type="checkbox" id="jack" value="Jack" v-model="checkedNames" /></th>
          <td class="px-6 py-4">{{ order.subtotal }}</td>
          <td class="px-6 py-4">{{ order.total }}</td>
          <td class="px-6 py-4">{{ order.items.length }}</td>
          <td class="px-6 py-4">{{ formatDate(order.created_at) }}</td>
          <td class="px-6 py-4">{{ formatDate(order.updated_at) }}</td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-changed="getOrders"
    />
 </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Pagination from '../../components/pagination.vue';
import routes from '../../router/index.js';
export default {
  name: 'OrdersView',
  components: {
    Pagination
  },
  data() {
    return {
      orders: [],
      checkedNames:[],
      currentPage: 1,
      pageSize: 10,
      totalPages: 0
    };
  },
  mounted() {
    this.getOrders(this.currentPage);
  },
  methods: { 
    onSelectedOrders(id){
      // routes.push({ path: `/t/products/${id}/overview` }) 
    },
    getOrders(page) {
      this.currentPage = page;
      axios.get('/t/orders', { 
        params: { page_size: this.pageSize, page: page },
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }).then(response => {
          this.orders = response.data.data;
          this.totalPages = Math.ceil(response.data.count / this.pageSize);
        })
        .catch(error => {
          console.log(error);
        });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
      }
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
button {
  margin: 0 5px;
}

</style>