<template>
  
  <div class="fixed overflow-x-auto max-h-full  min-h-full justify-between mt-32 mx-8 rounded-md">
    <button class="w-150 bg-teal-500 hover:bg-teal-700 mb-2 text-white font-bold py-2 px-4 rounded-md" @click="addProduct">
      <router-link :to="{ name: 'create-product' }">Add product</router-link>
    </button>
    
 <div class="fixed overflow-x-auto">
  <table class="fixed text-sm   text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-teal-100 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="px-6 py-3"><input type="checkbox" id="jack" value="Jack" v-model="checkedNames" /></th>
          <th scope="col" class="px-6 py-3">Image</th>
          <th scope="col" class="px-6 py-3">Name</th>
          <th scope="col" class="px-6 py-3">Category</th>
          <th scope="col" class="px-6 py-3">Subcategory</th>
          <th scope="col" class="px-6 py-3">Brand</th>
          <th scope="col" class="px-6 py-3">Description</th>
          <th scope="col" class="px-6 py-3">Status</th>
          <th scope="col" class="px-6 py-3">Price</th>
          <th scope="col" class="px-6 py-3">Quantity</th>
          <th scope="col" class="px-6 py-3">Created at</th>
          <th scope="col" class="px-6 py-3">Updated at</th>
        </tr>
      </thead>
      <tbody>

        <tr v-on:click="onSelectedProduct(product.id)"  class=" border-b rounded-md hover:bg-teal-50 odd:bg-white even:bg-slate-50" v-for="product in products" :key="product.id">
          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"><input type="checkbox" id="jack" value="Jack" v-model="checkedNames" /></th>
          <th scope="row" class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"><img :src=" product.image_thumbnail " alt="Product Image" class="flex-1 w-4 h-10 object-cover rounded" /></th>

          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{{ product.name }}</th>
          <td class="px-6 py-4 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">{{ product.category }}</td>
          <td class="px-6 py-4 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">{{ product.subcategory }}</td>
          <td class="px-6 py-4 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">{{ product.brand }}</td>

          <td class="px-6 py-4 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">{{ product.description }}</td>
          <td class="px-6 py-4">{{ product.is_active }}</td>
          <td class="px-6 py-4">{{ product.price }}</td>
          <td class="px-6 py-4">{{ product.quantity }}</td>
          <td class="px-6 py-4">{{ formatDate(product.created_at) }}</td>
          <td class="px-6 py-4">{{ formatDate(product.updated_at) }}</td>
        </tr>
      </tbody>
    </table>
    <Pagination
      :currentPage="currentPage"
      :totalPages="totalPages"
      @page-changed="getProduct"
    />
 </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Pagination from '../../components/pagination.vue';
import routes from '../../router/index.js';
export default {
  name: 'ProductsView',
  components: {
    Pagination
  },
  data() {
    return {
      products: [],
      checkedNames:[],
      currentPage: 1,
      pageSize: 10,
      totalPages: 0
    };
  },
  mounted() {
    this.getProduct(this.currentPage);
  },
  methods: { 
    onSelectedProduct(id){
      routes.push({ path: `/t/products/${id}/overview` }) 
    },
    getProduct(page) {
      this.currentPage = page;
      axios.get('/t/products', { 
        params: { page_size: this.pageSize, page: page },
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }).then(response => {
          this.products = response.data.results;
          this.totalPages = Math.ceil(response.data.count / this.pageSize);
        })
        .catch(error => {
          console.log(error);
        });
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY');
      }
    }
  }
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
button {
  margin: 0 5px;
}

</style>