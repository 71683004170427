<template >

  <div class="bg-background-color h-screen w-screen flex" >

<div class='w-600 lg:w-600 xl:w-600 2xl:w-600 m-auto p-4  content-center  bg-background-color' >
 <div class="h-auto px-8 py-8 content-center bg-primary-color rounded-large">
  <v-text-field label="name" variant="underlined" 
:rules="[rules.required,]"
v-model="name"
></v-text-field>
<v-text-field label="Number of employee" variant="underlined" 
:rules="[rules.required,]"
v-model="num_employee"
></v-text-field>
 <div>
  <v-text-field label="Commercial registertion" variant="underlined" 
:rules="[rules.required,]"
v-model="commercial_registertion"
></v-text-field>

<v-text-field label="Tax card" variant="underlined" 
:rules="[rules.required,]"
v-model="tax_card"
></v-text-field>  
</div>
<div class="content-center flex mt-4 justify-center"><v-btn
        class="text-none text-subtitle-1 center w-250"
        color="primary"
        size="large"
        variant="flat"
        :loading="loader"
        @click="create_company()"
        density="default"> Create
      </v-btn>
    </div>
</div>
</div>
</div>

</template>
<script >

import axios from 'axios'

export default {

  name: 'CreateCompany',
  methods:{
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          if (cookie.substring(0, name.length + 1) === (name + '=')) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
create_company(){
  this.loader = true;
    var data={
      "name":this.name,
      "commercial_registertion":this.commercial_registertion,
      "tax_card":this.tax_card,
      "num_employee":this.num_employee,
    };
    axios.post('t/company/create',data,{
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
      "X-CSRFToken":  this.getCookie('csrftoken')
    }
  }).then(response=>{
    var self = this;

    if(response.status==201){
      this.loader = false;
      self.$router.push({ path: '/dashboard' });

    }else{

    }

  });
  this.loader = false;
}
  },
  data() {
  return {
    loader: false,
    errorMessages:new Map(),
    name:'',
    commercial_registertion:'',
    tax_card:'',
    num_employee:'',
    rules: {
        required: value => !!value || 'This Field is Required.',
        counter: value => value.length <= 15 || 'Max 15 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
  
  };
},
}
</script>

  
  
  
  
  
  
  
  
  