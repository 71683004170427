



<template>
<!-- <Sidebar/> -->
<!-- <LoginView/> -->
<!-- <CreateCompany/> -->
<router-view />   

<!-- <RegisterView/> -->
</template>
<script >
export default  {
name:'App',
components :{
},
methods:{
  
}
};


</script>

<style lang="scss">


</style>
