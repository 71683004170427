<template>
  <div class="relative overflow-x-auto grow flex flex-col mt-32 mx-8 rounded-md">
    <!-- Header -->
    <div class="flex justify-between items-center mb-4">
      <div class="flex items-center space-x-2">
        <ClipboardDocumentCheckIcon class="w-5 h-5 text-teal-500" />
        <h1 class="text-2xl p-2">Add New Product</h1>
      </div>
      <router-link
        :to="{ name: 'create-product' }"
      >
       
      </router-link>
      <button
		  @click="submitForm"
		  class="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-lg inline-block"
		>
		  Add product
	  </button>
    </div>

    <!-- Form Container -->
    <div class="flex justify-between items-start space-x-4">
      <!-- General Information -->
      <div class="flex-1 p-4 bg-white rounded shadow-md">
        <form @submit.prevent="submitForm">
          <!-- Product Name -->
          <div class="mb-4">
            <h1 class="text-xl  mb-4">General Information</h1>

            <label for="productName" class="block text-gray-600 font-medium mb-2">Product Name</label>
            <div class="flex">
              <input
              v-model="form.productName"
              type="text"
              id="productName"
              class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter product name"
              required
            />
            </div>
          
          </div>

          <!-- Description -->
          <div class="mb-4">
            <label for="description" class="block text-gray-700 font-medium mb-2">Description</label>
          
            <div class="flex">
              <textarea
              v-model="form.description"
              id="description"
              class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter product description"
              rows="5"
              required
            ></textarea>
            </div>
            
          </div>

          <!-- Price -->
          <div class="mb-4">
            <label for="price" class="block text-gray-700 font-medium mb-2">Price</label>
            
            <div class="flex">
              <input
              v-model="form.price"
              type="number"
              id="price"
              class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter product price"
              required
            />
            </div>
            
          </div>

          <!-- Stock -->
          <!-- <div class="mb-6">
            <label for="stock" class="block text-gray-600 font-medium mb-2">Stock</label>
            
            <div class="flex">
              <input
              v-model="form.stock"
              type="number"
              id="stock"
              class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
              placeholder="Enter stock quantity"
              required
            />
            </div>
           
          </div>
           -->

          <div class="flex items-center mt-4 me-4">
            <input type="checkbox" id="checkbox" class="rounded bg-slate-800" v-model="form.has_variation" @change="onSelectVaraitaion" />
            <label class="ml-2 text-xs font-regular" for="checkbox">This product has different variants (colors, sizes, etc.)</label>
    </div>

    <div v-if="form.has_variation" >
      <div v-for="(attribute, index) in allVariationsDisplay" :key="index" class="transition duration-450  relative overflow-y-auto mt-4">
  <h1 class="text-xl mb-4">{{ attribute.name }}</h1>
  <div class="flex flex-wrap gap-2">
    <div v-for="(option, indexOption) in attribute.options" 
    :key="indexOption" class="flex-none ">
    <div :class="getClass(attribute,option)"
          @click="addOptionToProduct(attribute, option)"
    >
      <h1
        >
          {{ option.name }}
        </h1>
    </div>  

    </div>
  </div>
</div>
</div>


        </form>
      </div>

      <!-- Image Upload Section -->
  <div class="flex-1 rounded ">
  <div class="flex-1 p-4 bg-white rounded shadow-sm">
  <h2 class="text-xl mb-4">Upload Product Images</h2>
  <input
    type="file"
    multiple
    accept="image/jpeg, image/png"
    @change="handleFileUpload"
    class="mb-2 w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
  />
  
  <div v-if="form.images.length">
    <!-- First Image -->
    <div class="flex-1  relative max-h-120 w-full rounded mb-4 bg-black">
      <img :src="getImageUrl(form.images[0])" alt="Product Image" class="flex-1 min-w-full max-h-120 object-cover rounded" />
    </div>
    
    <!-- Remaining Images -->
    <div class="flex flex-wrap gap-2">
      <div v-for="(image, index) in form.images.slice(1)" :key="index" class="h-32 max-w-28 overflow-y-auto rounded border">
        <img :src="getImageUrl(image)" alt="Product Image" class="object-cover rounded w-full h-full" />
      </div>
    </div>
  </div>
  
</div>
<div class="flex-1 p-4  mt-4 bg-white rounded shadow-sm">
  <label for="price" class="block text-gray-700 font-medium mb-4">Brand</label>

  <Autocomplete
  :source= brands
  placeholder = "Select brand"
  v-model="form.brand"
  />

  <label for="price" class="block text-gray-700 font-medium mb-4 mt-4">Category</label>

  <Autocomplete
  :source= categories
  placeholder = "Select category" 
  v-model="form.category"
  @update:modelValue="updateCategory"

  />

  <label for="price" class="block text-gray-700 font-medium mb-4 mt-4">Subcategory</label>

<Autocomplete
:source= subcategories
placeholder = "Select subcategory" 
v-model="form.subcategory"

/>
</div>
  </div>
    </div>
  </div>
</template>

<script>
import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/solid';
import axios from 'axios';
import { useRouter } from 'vue-router';
import Autocomplete from '@/components/Autocomplete.vue'
export default {
  name: 'AddProduct',
  components: { ClipboardDocumentCheckIcon ,Autocomplete },
  setup() {
		const router = useRouter();
		return { router };
	},
  data() {
    return {
      allVariationsDisplay:[],
      categories:[],
      subcategories:[],
      brands:[],
      variations:null,
      image_source:[],
      form: {
        productName: '',
        description: '',
        category: {},
        price: null,
        brand: {},
        subcategory: {},
        stock: 0,
        images: [],
        variations:[],
        has_variation:false,
        has_discount:false,
      },
    };
  },
  mounted(){
  },
  created() {
    this.getBrands();
    this.getCategories();
    this.getAllAttributes();

  },
  methods: {
    updateCategory(category){
      if (!category.name.trim()) {
        this.form.subcategory = {};
        return;
      }
    
      if(category.id != null){
        console.log(category.id);
        this.getSubcategories(category.id);
      }
    },
    getSubcategories(id) {
		// Handle form submission
        axios.get('category/'+id+'/subcategory',  {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
)
      .then(response => {
        this.subcategories = response.data["data"];     
      })
      .catch(error => {
        console.log(error);
      });
		
	  },
    getBrands() {
		// Handle form submission
        axios.get('brands',  {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
)
      .then(response => {
        this.brands = response.data["data"];     
      })
      .catch(error => {
        console.log(error);
      });
		
	  },
    getCategories() {
		// Handle form submission
        axios.get('categories',  {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
)
      .then(response => {
        this.categories = response.data["data"];     
      })
      .catch(error => {
        console.log(error);
      });
		
	  },
    addOptionToProduct(attribute,option){
  
      let attributeFound = false;

if (this.form.variations.length === 0) {
  this.form.variations.push({
    id: attribute.id,
    value: attribute.name,
    options: [option.id]
  });
} else {
  this.form.variations.forEach((element) => {
    if (element.id === attribute.id) {
      if (!element.options.includes(option.id)) {
        element.options.push(option.id);
      }else{
        element.options.splice(element.options.indexOf(option.id), 1); 
      }
      attributeFound = true;
    }
  });

  if (!attributeFound) {
    this.form.variations.push({
      id: attribute.id,
      value: attribute.name,
      options: [option.id]
    });
  }
}
    },
    getClass(attribute, option) {
      const isSelectedOption = this.form.variations.some(
        (attr) => attr.id === attribute.id && attr.options.includes(option.id)
      );
      return {
          'border-2 text-center px-2 py-1 text-sm font-medium min-w-16 cursor-pointer rounded border-teal-500': isSelectedOption, // Example selected style
          'border text-center py-1 px-2 text-sm font-medium min-w-16 cursor-pointer rounded border-gray-300': !isSelectedOption // Example unselected style
        };
      // return `text-sm font-medium min-w-16 text-center mb-2  rounded p-1 cursor-pointer ${isSelectedOption ? 'border border-teal-600' : 'border border-slate-300'}`;
    },
    getImageUrl(file) {
    return URL.createObjectURL(file);
  },
    getAllAttributes() {
		// Handle form submission
        axios.get('/t/products/attributes',  {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
)
      .then(response => {
        this.allVariationsDisplay = response.data["data"];     
      })
      .catch(error => {
        console.log(error);
      });
		
	  },
    onSelectVaraitaion(event){
      // Your custom logic here
      if (event.target.checked) {
      
        // Do something when checked
        // this.getAttributes();
      } else {
        // Do something when unchecked
      }
    },
    submitForm(){

      console.log(this.form);
      // Create a new FormData object
      const formData = new FormData();

      // Append form fields to the FormData object
      formData.append("description", this.form.description);
      formData.append("price", this.form.price);
      formData.append("quantity", this.form.stock);
      formData.append("name", this.form.productName);
      formData.append("has_discount", this.form.has_discount);
      formData.append("has_variation", this.form.has_variation);
      formData.append("category", this.form.category.id);
      formData.append("subcategory", this.form.subcategory.id);
      formData.append("brand", this.form.brand.id);

      formData.append("variations", JSON.stringify(this.form.variations));
      this.form.images.forEach((file, index) => {
        formData.append("images", file);
      });

    axios.post('/t/products/create' ,formData, {

        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'multipart/form-data'
        }
    }
)
      .then(response => {
    this.form = {
        productName: '',
        description: '',
        price: null,
        stock: null,
        checked: true,
        category: {},
        brand: {},
        subcategory: {},
        images: [],
      };
      this.$router.go(-1);

      })
      .catch(error => {
        console.log(error);
      });
		  
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.form.images.push(files[i]);
      }
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
  },
};
</script>

<style scoped>
/* Add any additional styles here */


.selected-option {
  border: 2px solid blue; /* Example selected border */
  background-color: #f0f8ff; /* Example background color for selected option */
}

.unselected-option {
  border: 1px solid gray; /* Example unselected border */
  background-color: white; /* Example background color for unselected option */
}
</style>
