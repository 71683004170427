import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/home/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ProductsView from '../views/products/ProductsView.vue'
import ShowProduct from '../views/products/ShowProduct.vue'
import ImagesView from '../views/products/display/ImagesView.vue'
import OverviewView from '../views/products/display/OverviewView.vue'
import DetailsView from '../views/products/display/DetailsView.vue'

import VariantsView from '../views/products/display/VariantsView.vue'

import SettingsView from '../views/settings/SettingsView.vue'
import AttributesView from '../views/settings/attributes/AttributesView.vue'
import CreateAttributesView from '../views/settings/attributes/CreateAttributeView.vue'

import TransactionView from '../views/transaction/TransactionView.vue'
import OrdersView from '../views/orders/OrdersView.vue'
import CreateView from '../views/products/CreateView.vue'
import LoginView from '../views/auth/LoginView.vue'
import RegisterView from '../views/auth/RegisterView.vue'
import CreateCompanyView from '../views/company/CreateCompanyView.vue'
import DashboardView from '../views/dashboard/DashboardView.vue'
import StatisticView from '../views/statistics/StatisticsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    children: [
      {
        path: '/statistics',
        name: 'statistics',
        component: StatisticView
      },
      {
        path: '/products',
        name: 'products',
        component: ProductsView
      },
      {
        path: '/products/create',
        name: 'create-product',
        component: CreateView
      },
      {
        path: '/products/:id/overview',
        name: 'show-product',
        component: ShowProduct,
        children: [
          {
            path: '/products/:id/overview',
            name: 'overview-product',
            component: OverviewView
          },   
          {
            path: '/products/:id/details',
            name: 'details-product',
            component: DetailsView
          },
          
          {
            path: '/products/:id/variants',
            name: 'variants-product',
            component: VariantsView
          },
          
          {
            path: '/products/:id/images',
            name: 'image-product',
            component: ImagesView
          },
        ]
      },   
  
      {
        path: '/orders',
        name: 'orders',
        component: OrdersView
      },
      {
        path: '/settings/attributes',
        name: 'attributes',
        component: AttributesView,
      },
      {
        path: '/attributes/create',
        name: 'create-attribute',
        component: CreateAttributesView
      },
      // {
      //   path: 'settings',
      //   name: 'settings',
      //   component: SettingsView,
      //   children: [
       
      //     // {
      //     //   path: 'security',
      //     //   name: 'security',
      //     //   component: SecurityView,
      //     // },
      //   ]
      // },
    
      {
        path: '/transactions',
        name: 'transactions',
        component: TransactionView
      },
      {
        path: '/company/create',
        name: 'create-company',
        component: CreateCompanyView
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    component: RegisterView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router
