<script>
export default {
  props: {
    source: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object, // Expecting an object
      required: true,
    },
    placeholder: String,
  },
  data() {
    return {
      isOpen: false, // This should be a simple boolean, not a ref in Options API
      search: '', // This should be a string, not a ref in Options API
    };
  },
  computed: {
    searchResults() {
      if (this.search === '') {
        return this.source;
      } else {
        return this.source.filter((item) =>item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
  },
  methods: {
    handleInput(event) {
      this.isOpen = true;
      this.search = event.target.value;
      this.$emit('update:modelValue', { name: this.search });
    },
    setSelected(item) {
      this.isOpen = false;
      this.search = item.name;
      this.$emit('update:modelValue', item);
    },
  },
};
</script>

<template>
  <div>
    <div class="flex">
      <input
      :value="typeof modelValue === 'object' ? modelValue.name : modelValue"
      @input="handleInput"
        type="text"
        class="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500"
        :placeholder="placeholder"
        required
      />
    </div>
    <div class="dropdownitems">
      <ul v-show="searchResults.length && isOpen">
        <li
          v-for="item in searchResults"
          :key="item.name"
          @click="setSelected(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
