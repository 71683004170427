<template>
  <div class="w-1/3 flex-1 h-2">

    <aside class="flex flex-row justify-between bg-white shadow-sm w-full p-4">
    <h2 class="text-xl font-bold mb-4 justify-between">Sidebar</h2>
    <div class="flex flex-row space-x-8">
        <a href="#" class="text-gray-700 hover:text-gray-900">Dashboard</a>
        <a href="#" class="text-gray-700 hover:text-gray-900">Profile</a>
        <a href="#" class="text-gray-700 hover:text-gray-900">Settings</a>
        <a href="sign-up" class="text-gray-700 hover:text-gray-900">Register</a>
        <a href="login" class="text-gray-700 hover:text-gray-900">Login</a>

    </div>
</aside>

    <!-- Main Content -->
    <main class="flex-1 p-6">
        <div class="w-full h-full bg-white p-8 shadow-md">
            <h1 class="text-2xl font-bold mb-4">Home View</h1>
            <strong class="text-lg">Home</strong>
            <p class="mt-4 text-gray-600">
                Welcome to the home page! Here you can add any content or components you need to display.
            </p>
        </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  methods: {

  },
  created() {
              console.log(localStorage.getItem('token')); 

    console.log('HomeView created');
  },
  components: {
    
  }
}
</script>
