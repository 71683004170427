<template>
    <div v-if="product">


    <div class="grid xs:grid-cols-1 md:grid-cols-2 gap-4">
      <div class="bg-white p-4 rounded-md min-h-32" >
        <h1>Total stock</h1>
        <h2>{{ product.quantity }}</h2>

        </div>
        <div class="bg-white p-4 rounded-md min-h-32" >
          <h1>Total return</h1>

      </div>
      </div>

      <div class="flex mt-4 ">
  <div class="bg-white p-4 flex-col rounded-md xs:w-220 md:w-300 col-auto">
    <img :src="product.image_thumbnail" alt="Product Image" class="rounded-md" />
    <div class="flex-col mt-4">
      <h1 class="text-base not-italic "> Name </h1>
      <h3 class="text-sm text-gray-dd">{{ product.name }}</h3>
      <h1 class="text-base"> Description </h1>
      <h3 class="text-sm text-gray-dd">{{ product.description }}</h3>
      <h1 class=" text-base"> Category </h1>
      <h3 class="text-sm text-gray-dd">{{ product.category??"-----" }}</h3>
      <h1 class=" text-base"> Subcategory </h1>
      <h3 class="text-sm text-gray-dd">{{ product.subcategory??"-----" }}</h3>
      <h1 class=" text-base"> Brand </h1>
      <h3 class="text-sm text-gray-dd">{{ product.brand??" -----" }}</h3>
      <h1 class=" text-base"> Price </h1>
      <h3 class="text-sm text-gray-dd">{{ product.price }}</h3>

    </div>
  </div>
  
  <div class="bg-white p-4 ml-4 rounded-md max-h-66 justify-center  snap-x  grow">
    <!-- Content for the second div -->
    <table class=" text-x text-left overflow-scroll rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-600 uppercase border-b-2 border-gray-300  dark:bg-gray-700 dark:text-gray-400 border-1">
        <tr>

          <th scope="col" class="px-0 py-2">Sku</th>
          <th v-for="(header, index) in product.variations" :key="index" scope="col" class="px-6 py-2">{{ header.value }}</th>
          <th scope="col" class="px-6 py-2">Price</th>
          <th scope="col" class="px-6 py-2">Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr   class=" border-b " v-for="sku in product.skus" :key="sku.id">
          <td  class="text-xs px-0 py-4  text-gray-dd">{{ sku.sku }}</td>
          <td v-for="(option, index) in sku.options" :key="index" class="text-sm px-6 py-4 text-gray-dd">{{ option.value }}</td>
          <td class="px-6 py-4">{{ sku.price }}</td>
          <td class="px-6 py-4">{{ sku.stock }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

    </div>
  </template>
  
  <script>
  
  import axios from 'axios';
  export default {
    name: 'OverviewProduct',
    data(){
      return{
        product: null,
      }
    },
    props: {
    
    },
    created() {
      
      this.fetchProduct();
    },
    methods:{

      getOptions(options) {
      return options.map(option => option.value).join(', ');
    },
      fetchProduct() {
        const productId = this.$route.params.id;
        axios.get(`/t/products/${productId}/overview`,
          {
            headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
          }
        )
          .then(response => {
            this.product = response.data;
            if(this.product['skus'].length>1){
              this.items.push('Variants');
            }
          })
          .catch(error => {
            console.error('Error fetching product:', error);
          });
      }

    },
  };
  </script>
  
  <style scoped>
  /* Add styles for product details here */
  </style>
  