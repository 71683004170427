<template>
  <v-card
    class="w-600 lg:w-600 xl:w-600 2xl:w-600 py-8 px-6 rounded-xl text-center"
    elevation="1"
    width="100%"
  >
    <h3 class="text-h6 mb-4">Verify Your Account</h3>

    <div class="text-body-2">
      We sent a verification code to {{phone}} <br>

      Please check your email and paste the code below.
    </div>

    <v-sheet color="surface">
      <v-otp-input
        v-model="otp"
        :on-finish=verifyUser()
      ></v-otp-input>
    </v-sheet>

    <div class="text-caption">
      Didn't receive the code? <a href="#" @click.prevent="otp = ''">Resend</a>
    </div>
  </v-card>
 
  </template>
  
  <script>
import axios from 'axios'


  export default {
    name: 'VerifyView',
    props:{
   phone: String,
   isLogin: Boolean
 },methods:{
  verifyUser(){

    if(this.otp!=null &&this.otp.length==6){
      var data={
    "phone_number":parseInt(this.phone),
    "dail_code": "20",
    "otp":this.otp
    };
    axios.post('/t/accounts/verify',data).then(response=>{
      var self = this;
    if(response.status==200){
      localStorage.setItem('token',response.data['token']);
      if(this.isLogin){
        self.$router.push({ name: 'dashboard' });
      }else{
        self.$router.push({ name: 'create-company' });
      }
    }else{

    }
  });
  // .catch(error=>{
  //   if(error.response.status==422){
  //     this.errorMessages=error.response.data['errors'];
  //   }
  // });

    }
  
  }
 }
  ,computed:{
   
},
data(){
  return {
    otp: null,
    errorMessages:[]

  }
}
}
  </script>

