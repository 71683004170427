
<template>


    <div class="w-screen h-screen flex">
		<!-- Sidebar -->
            <sidebar/>

        <div class="grow bg-background-color-2 ">
            <router-view/>          
               <!-- main -->

        </div> 
	</div>
    </template>
    <script >

    import sidebar from '@/components/sidebar.vue';
    export default  {
    name:'Dashboard',
    components :{
        sidebar,
    },
    };
    </script>
    <style lang="scss">
    </style>